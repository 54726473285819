<page-title>{{'Coordinator'|translate}}</page-title>
<div class="coordinator contentWrapper">
	<div class="leftPane">
		<div class="topPane">
			<div class="toolbar airports">
				<div *ngFor="let airport of airports|orderBy:['ICAOCode']"
					 class="airport"
					 [class.selected]="selectedAirport?.Id === airport.Id"
					 (click)="selectAirport(airport)">
					<div class="airportCodeWrapper">
						<span class="airportCode">{{$root.currentUser.UseIataCode ? airport.IATACode : airport.ICAOCode}}</span>
					</div>
					<div *ngFor="let clientAirport of (clientAirports || [])|filter:{ClientId:selectedAirline?.ClientId || -1,AirportId:airport.Id}:true" class="weather" [title]="clientAirport.HotResponse.Type === 'LWE' ? clientAirport.HotResponse.WeatherType : clientAirport.WeatherTypeNameResult">
						<weather-icon [airport]="clientAirport"></weather-icon>
						<span class="temperature">{{clientAirport.HotResponse.Temperature|formatTemperature:clientAirport.HotResponse.Type:0}}</span>
					</div>
				</div>
			</div>
			<div class="airlines">
				<div *ngFor="let airline of airlines|orderBy:['ICAOCode']"
				     class="airline"
				     [class.selected]="selectedAirline?.Id === airline.Id"
				     (click)="selectAirline(airline)">
					<div *ngIf="airline.CoordinatorLogo" class="airlineLogo" title="{{$root.currentUser.UseIataCode ? airline.IATACode : airline.ICAOCode}}" [style.background-image]="'url(' + airline.CoordinatorLogo + ')'"></div>
					<div *ngIf="!airline.CoordinatorLogo" class="airlineCode">{{$root.currentUser.UseIataCode ? airline.IATACode : airline.ICAOCode}}</div>
				</div>
			</div>
		</div>
		<div class="horizontalBorder"></div>
		<div class="bottomPane">
            <grid #deicingRequestGrid
                  gridId="preferences.CoordinatorDeicingRequest.ColumnExport"
                  *ngIf="'CoordinatorDeicingRequests'|hasPermission"
                  [columnDefs]="deicingRequestColumnDefs"
                  [query]="{AirportId: this.selectedAirport == null ? null : this.selectedAirport.Id, AirlineId: this.selectedAirline == null ? null : this.selectedAirline.Id}"
                  [resource]="api.CoordinatorDeicingRequest">
            </grid>
		</div>
	</div>
	<div class="verticalBorder"></div>
    <div class="rightPane">
        <div>
            <div class="airportCodeWrapper">
                <span class="hotConditionIcon mdi mdi-map-marker" *ngIf="selectedClientAirport" [title]="hotResponse.HotConditionName" [style.color]="'#' + (hotResponse.HotConditionColor || 'aaaaaa')"></span>
                <span *ngIf="selectedClientAirport && (hotResponse.Type === 'LWE' || selectedClientAirport.StationSimulationId || selectedClientAirport.StationGen2Id || selectedClientAirport.StationGen3AId || selectedClientAirport.StationGen3BId || selectedClientAirport.StationGenVId)" class="plus" [title]="'LWE'|translate">+</span>
                <span class="airportCode" *ngIf="selectedClientAirport">{{$root.currentUser.UseIataCode ? selectedClientAirport.IATACode : selectedClientAirport.ICAOCode}}</span>
                <weather-icon *ngIf="selectedClientAirport" [airport]="selectedClientAirport"></weather-icon>
                <button type="button" class="refresh" (click)="manualRefresh()"><span class="mdi mdi-refresh"></span></button>
				<div class="requestTime" *ngIf="selectedClientAirport">
					<span class="mdi mdi-clock"></span>{{$root.currentUser.UseLocalTime && selectedClientAirport.TimeZone ? (moment.utc(selectedClientAirport.RequestDateTime).tz(selectedClientAirport.TimeZone).format($root.dateFormat + ' ' + $root.timeFormat) + ' LT') : (moment.utc(selectedClientAirport.RequestDateTime).format($root.dateFormat + ' ' + $root.timeFormat) + ' Z')}}
				</div>
            </div>
            
	        <portlets>
		        <portlet [title]="'HOTs'" [name]="'hots'" class="hots" *ngIf="selectedClientAirport">
		            <form #form #ngOfflineHotForm="ngForm" (submit)="getManualHotResponse()">
	                    <table class="table table-striped table-hover">
	                        <tr *ngIf="!manualEntryMode">
	                            <td class="col-md-3">{{hotResponse.Type}} {{'Timestamp'|translate}}</td>
								<td class="col-md-9">{{$root.currentUser.UseLocalTime && selectedClientAirport.TimeZone ? (moment.utc(hotResponse.DateTime).tz(selectedClientAirport.TimeZone).format($root.timeFormat) + ' LT') : (moment.utc(hotResponse.DateTime).format($root.timeFormat) + ' Z')}}</td>
	                        </tr>
	                        <tr *ngIf="hotResponse.Temperature !== null">
	                            <td class="col-md-3">{{manualEntryMode ? 'Manual Override' : hotResponse.Type}} {{'Temperature'|translate}}</td>
	                            <td class="col-md-9">
	                                <button *ngIf="!manualEntryMode" type="button" class="editTemperature" (click)="goOffline()"><span class="mdi mdi-pencil"></span></button>
	                                <span *ngIf="manualEntryMode">
	                                    <input type="number" [(ngModel)]="temperature" (ngModelChange)="getManualHotResponse()" name="temperature" required step="1" [min]="minTemp" style="width: 70px;" />
	                                    {{$root.currentUser.UseFahrenheit ? ' \u00B0F' : ' \u00B0C'}}
	                                </span>
	                                <span *ngIf="!manualEntryMode">{{hotResponse.Temperature|formatTemperature:hotResponse.Type}}</span>
	                            </td>
	                        </tr>
	                        <tr *ngIf="hotResponse.WeatherReport || hotResponse.WeatherTypeName">
	                            <td class="col-md-3">{{manualEntryMode ? 'Manual Override' : hotResponse.Type}} {{'Weather'|translate}}</td>
	                            <td class="col-md-9">
	                                <div class="weatherTypeSelection" *ngIf="manualEntryMode">
	                                    <div class="caption"><span class="mdi mdi-weather-cloudy mdi-24px"></span> {{'Precipitation'|translate}}</div>
	                                    <div class="weatherType">{{selectedWeatherMenuItem?.Name || ''}}</div>
	                                    <button type="button" (click)="showWeatherTypePopup()" class="showWeatherTypePopup"><span class="mdi mdi-pencil"></span></button>
	                                </div>
	                                <div class="intensitySelection" *ngIf="manualEntryMode && selectedWeatherMenuItem && (selectedWeatherMenuItem.Items?.length > 0 || selectedWeatherMenuItem.SnowVisibilityMenu != null)">
	                                    <div class="caption"><span class="mdi mdi-weather-snowy mdi-24px"></span> {{selectedWeatherMenuItem.SnowVisibilityMenu.SelectedIntensityTitle}}</div>
	                                    <div class="intensity">{{selectedVisibility?.Name ?? selectedIntensity?.Name || ''}}</div>
	                                    <button type="button" (click)="showIntensityPopup()" class="showIntensityPopup"><span class="mdi mdi-pencil"></span></button>
	                                </div>
	                                <button *ngIf="!manualEntryMode" type="button" class="editWeatherType" (click)="editWeatherType()"><span class="mdi mdi-pencil"></span></button>
	                                <span *ngIf="!manualEntryMode">{{hotResponse.Type == 'METAR' ? hotResponse.WeatherReport : hotResponse.WeatherTypeName}}</span>
	                            </td>
	                        </tr>
	                        <tr *ngIf="selectedClientAirport.MetarReadingString">
	                            <td class="col-md-3">{{'METAR'|translate}}</td>
	                            <td class="col-md-9">{{selectedClientAirport.MetarReadingString}}</td>
	                        </tr>
	                    </table>

	                    <div *ngFor="let hot of hotResponse?.HotResponseHots|orderBy:['FluidOrder', 'FluidType', 'FluidName', '-SortableDilution']">
	                        <div class="row">
	                            <div class="col-md-4 fluid"
	                                 [class.type1]="hot.FluidType === 'Type1'"
	                                 [class.type2]="hot.FluidType === 'Type2'"
	                                 [class.type3]="hot.FluidType === 'Type3'"
	                                 [class.type4]="hot.FluidType === 'Type4'"
	                                 [class.generic]="hot.IsGeneric"
	                                 [class.composite]="hot.IsComposite">
	                                <div style="font-weight: bold; font-size: 18px; margin-bottom: 7px;">
	                                    TYPE {{formatFluidType(hot.FluidTypeNumber)}}
	                                    <div class="lineSample"
	                                         [class.type1]="hot.FluidType === 'Type1'"
	                                         [class.type2]="hot.FluidType === 'Type2'"
	                                         [class.type3]="hot.FluidType === 'Type3'"
	                                         [class.type4]="hot.FluidType === 'Type4'"
	                                         [class.dilution75]="hot.FluidDilution === '75/25'"
	                                         [class.dilution50]="hot.FluidDilution === '50/50'"
	                                         [class.composite]="hot.IsComposite"
	                                         [class.generic]="hot.IsGeneric"></div>
	                                </div>
	                                <div>
	                                    <span>{{hot.FluidName}}</span>
	                                    <span *ngIf="hot.FluidType != 'Type1'">{{hot.FluidDilution}}</span>
	                                </div>
	                            </div>

	                            <div class="col-md-8" style="margin-left: 10px;">
	                                <div *ngIf="!isEmpty(hot.NonHotMessage)">
	                                    <span style="white-space: pre-wrap;">{{hot.NonHotMessage}}</span>
	                                </div>
	                                <div *ngIf="!isEmpty(hot.MinHot)">
	                                    <div style="margin: 0px 0px 9px 15px; overflow: hidden;">
	                                        <span [style.display]="getHotStyle(hot).min.display" [style.width.%]="getHotStyle(hot).min.width" [style.text-align]="getHotStyle(hot).min['text-align']" [style.float]="getHotStyle(hot).min.float">{{hot.MinHot}} {{'MIN'|translate}}</span>
	                                        <span [style.display]="getHotStyle(hot).max.display" [style.width.%]="getHotStyle(hot).max.width" [style.text-align]="getHotStyle(hot).max['text-align']" [style.float]="getHotStyle(hot).max.float" *ngIf="!isEmpty(hot.MaxHot)">{{getHotStyle(hot).separator}}{{hot.MaxHot}} {{'MIN'|translate}}</span>
	                                    </div>
	                                    <div class="timer">
	                                        <div class="middle">
	                                            <div class="progressBar">
	                                                <div class="upperProgressBar thin" style="background-color: gray;"></div>
	                                                <div *ngIf="!isEmpty(hot.MaxHot)" class="lowerProgressBar thin min" [style.width.%]="((hot.MaxHot * 100)/maxHOTConfig)"></div>
	                                                <div *ngIf="!isEmpty(hot.MinHot)" class="lowerProgressBar thin max" [style.width.%]="((hot.MinHot * 100)/maxHOTConfig)"></div>
	                                            </div>
	                                        </div>
	                                    </div>
	                                </div>
	                            </div>
	                        </div>
	                    </div>
					</form>
			    </portlet>
	            
		        <portlet [title]="'HOT graph'" [name]="'hot-graph'" class="hot-graph" *ngIf="('CoordinatorHotGraph'|hasPermission) && !manualEntryMode && selectedClientAirport && graphHots != null && graphHots.size > 0 && selectedClientAirportFluids?.length > 0">
					<hot-graph [data]="graphHots" [fluids]="selectedClientAirportFluids" [count]="graphHotsCount" [useFluidColors]="true" [options]="graphOptions"></hot-graph>
				</portlet>
				
	            <portlet [title]="'HOT request history'" [name]="'hot-request-history'" class="hot-request-history" *ngIf="('CoordinatorHotRequestHistory'|hasPermission) && selectedClientAirport && hotRequests">
		            <label>
			            <span class="caption">{{'Request type'|translate}}</span>
			            <multiselect name="hotRequestQuery.HotRequestType" [(ngModel)]="hotRequestQuery.HotRequestType" (ngModelChange)="hotRequestQueryChanged($event)">
				            <multiselect-option *ngFor="let hotRequestType of hotRequestTypes|keys" [value]="hotRequestType" [name]="hotRequestTypes[hotRequestType]"></multiselect-option>
			            </multiselect>
		            </label>
		            <br />
		            <hot-request-history [data]="hotRequests" [fluids]="selectedClientAirportFluids"></hot-request-history>
	            </portlet>
	        </portlets>
        </div>
    </div>
</div>
